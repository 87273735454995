import React from "react";

export const Description = (props) => {
  return (
    <div id="about">
      
      <div className="container">
        <div className="row">
          <div  className="col-xs-12 col-md-6">
            {""}
            <img src="img/images/kosticabuildingone.jpeg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text fade-in">
              <h2 className="header-effect fade-in" style={{fontSize: "32px", textAlign: "center"}}>תכנון בניה</h2>
              <p className="para-about header-about">בתחום זה המשרד מייצג יזמים בתחום המקרקעין המבקשים לבצע בניה ומסייע בהבנת תוכניות היתרים וזכויות בניה הנובעים מהן. המשרד מלווה את הלקוח בכל הנוגע בתחום ונדרש לתת חוות דעת משפטיות ולייצג בבתי המשפט השוניםבתחום זה.</p>
            
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                 
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
