import React from "react";

export const AboutReverse = (props) => {
  return (
    <div id="about">
      
      <div className="container">
        <div className="row">
          <div  className="col-xs-12 col-md-12">
            <h2> </h2>
                <h1 className="header-text fade-in header-effect"><h1>  בין לקוחותינו נמנים :</h1></h1>
          </div>
          <div className="col-xs-12 col-md-12">
            <div  className="about-text">
              <ul className="ul-clients">
                <br/>
                <li>קבוצת R2M (קופי בר, בראסרי, הוטל מונטיפיורי, בייקרי)</li>
                <li>קבוצת we like you too</li>
                <li>קבוצת מלונות 7minds</li>
                <li>רשת לנדוור</li>
                <li> רשת ג׳פניקה</li>
                <li> רשת מוזס </li>
                <li> ועוד רבים וטובים</li>
              </ul>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
