import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            
            <img src="img/images/midtown.jpeg" className="img-responsive img-fluid" alt="" style={{height: '600px'}}/>
           <span style={{color: '#fff'}}>המשרד ממוקם בבנין מידטאון דרך בגין 144א ת״א</span>
          </div>
          <div className="col-xs-12 col-md-6">
            <div  className="about-text">
              <h1 className="header-text fade-in header-effect">קצת עלינו:</h1>
              <p className="para-about text-effect">קוסטיקה משרד עורכי דין הוקם בשנת 2004 על ידי עורך דין יוסף אסף קוסטיקה <br/>

המשרד הממוקם בלב הסיטי של תל אביב משמש כמשרד מייעץ ומייצג בעולם העסקים<br/>

המשרד נותן ייעוץ החל ממחשבה ותכנון עסק דרך פעילותו ועד למכירתו או סגירתו.

התמחויות העומק הן ליטיגציה, רישוי עסקים תכנון ובניה ומקרקעין

למשרד לקוחות רבים ובולטים שמקבלים ליווי נאמן שנים רבות.</p><br/>
{/* <ul>
<h3>  בין לקוחותינו נמנים :
</h3><br/>
<li>קבוצת R2M (קופי בר, בראסרי, הוטל מונטיפיורי, בייקרי)</li>
<li>קבוצת we like you too</li>
<li>קבוצת מלונות 7minds</li>
<li>רשת לנדוור</li>
<li> רשת ג׳פניקה</li>
<li> רשת מוזס ועוד רבים וטובים</li>
</ul> */}

              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
