import React from "react";

export const AboutLast = (props) => {
  return (
    <div id="about">
      <div  className="container">
        <div className="row">
          {/* <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/images/montiresttwo.jpg" className="img-responsive" alt=""/>{" "}
          </div> */}
          <div className="col-xs-12 col-md-12">
            <div className="about-text fade-in">
              <h1 className="header-text fade-in header-effect">מקרקעין</h1>
              <p className="para-aboutlast fade-in text-effect">בעולם המקרקעין המשרד מייצג ברכישות בכינוסי נכסים ובליטיגציה אזרחית בתחום. המשרד מלווה את הלקוח בכל אלמנט הדרוש להשגת מטרתו ונותן ערך מוסף בשל התמחות בעולמות משיקים שונים<br/><br/><br/>המשרד רואה עצמו כזרוע משפטית עסקית של הלקוחות שלו שמטרתה היא הצלחה עבור הלקוח </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
