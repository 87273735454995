import React from "react";

export const AboutMore = (props) => {
  return (
    <div id="about">
      
      <div className="container">
        <div className="row-reverse">
          <div  className="col-xs-12 col-md-6">
            {""}
            <img src="img/images/montiresttwo.jpg"  className="img-responsive img-flui" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div  className="about-text fade-in">
              <h1 className="header-text fade-in header-effect">רישוי עסקים</h1>
              <p className="para-about text-effect">בהתמחות עומק זו המשרד מייצג חלק מקבוצות עסקי האירוח הגדולות במשק.<br/> המשרד מלווה את הלקוח כבר מרעיון הקמת העסק ובדיקת היתכנותו דרך הליך הרישוי כולו והייצוג הנדרש בתחום בבתי המשפט השונים</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
