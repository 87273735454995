import React, { useState, useEffect, useRef } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import { AboutLast } from "./components/aboutlast";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { AboutMore } from './components/aboutmore'
import { Description } from "./components/description";
import { scrollSpy } from "react-scroll";
import { AboutReverse } from "./components/aboutreverse";



export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

scrollSpy.update();

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  // Create a ref for each section
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const galleryRef = useRef(null);
  const descriptionRef = useRef(null);
  const testimonialsRef = useRef(null);
  const teamRef = useRef(null);
  const aboutLastRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    // Create the IntersectionObserver object
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // If the section is in view, add the animation class
          if (entry.isIntersecting) {
            const textElements = entry.target.querySelectorAll('.fade-in');
            textElements.forEach((element) => {
              element.classList.add('visible');
            });
          } else {
            // If the section is not in view, remove the animation class
            const textElements = entry.target.querySelectorAll('.fade-in');
            textElements.forEach((element) => {
              element.classList.remove('visible');
            });
          }
        });
      },
      { threshold: 0.5 } // Trigger when the section is 50% in view
    );

    // Observe each section
    observer.observe(aboutRef.current);
    observer.observe(servicesRef.current);
    observer.observe(galleryRef.current);
    observer.observe(descriptionRef.current);
    observer.observe(testimonialsRef.current);
    observer.observe(teamRef.current);
    observer.observe(aboutLastRef.current);
    observer.observe(contactRef.current);

    // Cleanup function to disconnect the observer
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <div ref={aboutRef} id="about">
        <About data={landingPageData.About} />
      </div>
      <div ref={servicesRef} id="services">
        <Services data={landingPageData.Services} />
      </div>
      <div ref={galleryRef} id="gallery">
        <AboutMore data={landingPageData.About} />
      </div>
      <div >
        <Gallery data={landingPageData.Gallery} />
      </div>
      <div ref={descriptionRef} id="description">
        <Description data={landingPageData.Testimonials} />
      </div>
      <div ref={testimonialsRef} id="testimonials">
        <Testimonials data={landingPageData.Testimonials} />
      </div>
      <div>
        
      </div>
      <div ref={aboutLastRef} id="aboutLast">
         <AboutLast data={landingPageData.About} />
      </div>
      <div ref={teamRef} id="team">
        <AboutReverse />
      </div>
      <div ref={contactRef} id="contact">
        <Contact data={landingPageData.Contact} />
      </div>
    </div>
  );
};

export default App;