import React from "react";




export const Navigation = (props) => {

  return (
    <nav id="menu" className="navbar navbar-defaul navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '26px',marginLeft: '-50px'}} className="navbar-brand page-scroll" href="#page-top">
            <img src="/img/images/Kostica_Logo.svg" alt="" style={{height: '118px'}}/>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
              <li>
            </li>
              <li>
              <a href="#about" className="page-scroll">
                קצת עלינו
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                השירותים שלנו
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                גלריה
              </a>
            </li>
            <li>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                לקוחות              
                </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                צור קשר
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
